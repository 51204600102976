<template>
  <section id="editReel">
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <!-- NAME AND SAVE BUTTON -->
      <b-row class="form-group">
        <b-col class="d-flex justify-content-between">
          <b-form-group
            :label="$t('name')"
            label-size="sm"
            label-class="labelForm"
            style="flex-grow: 1;"
          >
            <validation-provider
              v-slot="validationContext"
              name="Name"
              :rules="{
                required: true,
                min: 3,
                regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]+$/
              }"
            >
              <b-form-input
                v-model="reel.name"
                :state="validationContext.errors.length > 0 ? false : null"
                :placeholder="$t('reels.enterName')"
                maxlength="150"
              />
              <small class="text-danger">{{ fix(validationContext) }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            size="md"
            class="ml-1 mt-1 text-nowrap"
            style="height: fit-content;"
            variant="success"
            @click="validationFormInfo()"
          >
            {{ $t('dataGeneric.save') }}
          </b-button>
        </b-col>
      </b-row>
      <!-- DESCRIPTION -->
      <b-row class="form-group">
        <b-col>
          <b-form-group
            :label="$t('resources.tableHeader.description')"
            label-size="sm"
            label-class="labelForm"
          >
            <validation-provider
              v-slot="{ errors }"
              name="description"
              :rules="{
                regex: /^[a-zA-Z0-9\s.,'!?()-áéíóú]*$/
              }"
            >
              <b-form-textarea
                v-model="reel.description"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('reels.enterDescription')"
                maxlength="180"
                rows="2"
                no-resize
                class="overflow-scroll"
              />
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- UPDATE REEL METHOD -->
      <b-row
        v-if="base == language"
        class="form-group"
      >
        <b-col>
          <b-form-select
            v-model="reel.mediaLocation"
            :options="editUploadMethods"
            @change="cleanFiles()"
          />
        </b-col>
      </b-row>
      <b-row class="form-group">
        <!-- BY URL -->
        <b-col
          v-if="reel.mediaLocation === 'DIR' && base == language"
        >
          <b-row class="form-group">
            <b-col class="d-flex">
              <b-form-input
                id="newUrlInput"
                v-model="reel.fileUrl"
                :state="editUrlValidation"
                :placeholder="$t('reels.newUrl')"
                type="url"
              />
              <b-button
                variant="primary"
                class=" ml-1 text-nowrap"
                @click="checkUrl(reel.fileUrl)"
              >
                {{ $t('reels.loadUrl') }}
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <!-- BY BUK FILE -->
        <b-col
          v-else-if="reel.mediaLocation === 'BUK' && base == language"
        >
          <b-row class="form-group">
            <b-col>
              <b-card
                class="dropContainer p-1 mb-0 text-center cursor-pointer color-black"
                style="background-color: white"
                @click="selectEditFile()"
                @dragenter.prevent
                @dragover.prevent
                @drop.prevent="uploadReelBuk"
              >
                <b-row>
                  <b-col>
                    <h4 style="color: black">
                      {{ $t("reels.singleReelToCard") }}
                    </h4>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-button variant="primary">
                      {{ $t('reels.uploadReel') }}
                    </b-button>
                  </b-col>
                </b-row>
                <b-form-file
                  id="editInput"
                  v-model="editInput"
                  class="mr-1 d-none"
                  accept="video/*"
                />
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
  </section>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BFormSelect,
  BFormFile,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

export default {
  components: {
    BButton,
    BCard,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BRow,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    base: {
      type: String,
      default: null,
    },
    language: {
      type: String,
      default: null,
    },
    originalObject: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      descriptionValidation: null,
      editInput: null,
      editUploadMethods: [
        { value: 'BUK', text: this.$t('reels.uploadReel') },
        { value: 'DIR', text: this.$t('url') },
      ],
      editUrlValidation: null,
      headers: null,
      nameValidation: null,
      reel: JSON.parse(JSON.stringify(this.originalObject)),
      required,
      userData: getUserData(),
    }
  },
  watch: {
    editInput(newValue) {
      if (newValue) {
        const bukReelTemporalUrl = URL.createObjectURL(newValue)
        this.$emit('updateUrlEditModal', bukReelTemporalUrl)
        this.removeModalPadding()
      }
    },
  },
  mounted() {
    this.getHeaders()
    if (this.reel) {
      this.getReel()
    }
  },
  methods: {
    checkUrl(url) {
      if (url && this.isUrlValid(url)) {
        this.editUrlValidation = true
        this.$emit('updateUrlEditModal', url)
        this.removeModalPadding()
      } else {
        this.editUrlValidation = false
      }
    },
    cleanBukFile() {
      this.editInput = null
    },
    cleanEditUrl() {
      this.reel.fileUrl = ''
      this.editUrlValidation = null
    },
    cleanFiles() {
      this.cleanEditUrl()
      this.cleanBukFile()
    },
    fix(data) {
      let message
      const keyExists = Object.keys(data.failedRules)
      if (keyExists.includes('required')) {
        message = this.$t('purchases.nameFormValid')
      }
      if (keyExists.includes('min')) {
        message = this.$t('purchases.nameFormThreeCaract')
      }
      return message
    },
    getHeaders() {
      let defaultLang = 'es'
      try {
        defaultLang = this.userData.profile.client.defaultLanguage
      } catch (error) {
      // eslint-disable-next-line
      console.log(error)
      }
      this.headers = {
        'Accept-Language': defaultLang,
        'Content-Language': defaultLang,
      }
    },
    getReel() {
      const { headers } = this
      axios
        .post('', {
          variables: {
            id: this.reel.id,
            language: this.language,
          },
          query: `
            query ($id:String, $language:String) {
              allReel (id:$id) {
                edges {
                  node {
                    id
                    name(lang:$language)
                    description(lang:$language)
                  }
                }
              }
            }
          `,
        }, { headers })
        .then(response => {
          messageError(response)
          this.reel.name = response.data.data.allReel.edges[0].node.name
          this.reel.description = response.data.data.allReel.edges[0].node.description
        })
    },
    isUrlValid(url) {
      if (url.trim() === '') {
        return false
      }
      const videoFormatRegex = /\.(mp4|mov|avi|mkv|mpg|mpeg|wmv)$/i
      const inyectionRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i
      if (videoFormatRegex.test(url) && inyectionRegex.test(url)) return true
      return false
    },
    removeModalPadding() {
      // BootstrapVue is adding a random padding when new reel is displayed
      const modal = document.querySelector('#editReelModal')
      modal.classList.add('pl-0')
    },
    selectEditFile() {
      document.getElementById('editInput').click()
    },
    updateReel() {
      if (this.validateInputs(this.reel)) {
        const { headers } = this
        const data = new FormData()

        const {
          originalObject, reel, editInput, language, base,
        } = this

        let objectValues = []
        objectValues = [
          { title: `name${language}`, value: reel.name, type: 'String' },
          { title: `description${language}`, value: reel.description, type: 'String' },
        ]

        if (language === base) {
          if (originalObject.mediaLocation !== reel.mediaLocation) {
            objectValues.push({ title: 'mediaLocation', value: reel.mediaLocation, type: 'ContentsReelMediaLocationChoices' })
            if (reel.mediaLocation === 'DIR' && reel.fileUrl && reel.fileUrl !== originalObject.fileUrl) {
              objectValues.push({ title: 'videoUrl', value: reel.fileUrl, type: 'String' })
            } else if (reel.mediaLocation === 'BUK' && editInput) {
              data.append('video', editInput)
            }
          } else if (reel.mediaLocation === 'DIR' && reel.fileUrl && reel.fileUrl !== originalObject.fileUrl) {
            objectValues.push({ title: 'videoUrl', value: reel.fileUrl, type: 'String' })
          } else if (reel.mediaLocation === 'BUK' && editInput) {
            data.append('video', editInput)
          }
        }

        const values = {}
        let mutation = 'mutation ($id: ID!,'
        let query = 'updateReel(id: $id, input: {\n'

        values.id = reel.id
        objectValues.forEach(element => {
          values[element.title] = element.value
          mutation += `$${element.title}:${element.type},\n`
          query += `${element.title}:$${element.title},\n`
        })

        mutation += ') {\n'
        query += `
            }){
              reel{
                id
                name
                description
                createdAt
                numViews
                isActive
                mediaLocation
                videoUrl
              }
            }
          }
        `
        query = mutation + query
        data.append('query', query)
        data.append('variables', JSON.stringify(values))
        axios
          .post('', data, { headers })
          .then(response => {
            if (response.data.data.updateReel.reel.id) {
              showToast(this.$t('reels.updateSuccess'), 1, this)
              this.$emit('editUploadReel', response.data.data.updateReel.reel)
              this.$emit('hideModal', 'editReelModal')
              this.reel = null
            } else {
              showToast(this.$t('reels.updateError'), 2, this)
            }
          }).catch(res => {
          // eslint-disable-next-line
          console.log(res)
            showToast(this.$t('reels.updateError'), 2, this)
          })
      }
    },
    uploadReelBuk(event) {
      event.dataTransfer.files.forEach(element => {
        if (this.validateFileName(element.name)) {
          this.editInput = element
        }
      })
    },
    validateInputs(reel) {
      const nameRegex = /^[a-zA-Z0-9\s.,'"!?()-áéíóú]+$/
      const descriptionRegex = /^[a-zA-Z0-9\s.,'"!?()-áéíóú]*$/

      this.nameValidation = nameRegex.test(reel.name) ? null : false
      this.descriptionValidation = descriptionRegex.test(reel.description) ? null : false

      if (this.nameValidation === null && this.descriptionValidation === null) {
        return true
      }
      return false
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.updateReel()
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
  textarea::-webkit-scrollbar {
    display: none
  }

  .labelForm {
    margin-bottom: 0.5rem !important;
  }
</style>
